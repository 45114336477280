import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faChevronRight,
  faFolder
} from '@fortawesome/pro-solid-svg-icons';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import ActionMenu, {
  DeleteMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import AvatarGroupButton from 'components/AvatarGroupButton';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import MembersModal from 'components/modals/MembersModal';
import CollectionCreationForm from 'features/collection/CollectionCreationForm';
import AttachmentList from 'features/attachment/AttachmentList';
import api from 'utils/api';
import FolderCollections from './FolderCollections';
import FolderCreateMenu from './FolderCreateMenu';
import FolderForm from './FolderForm';

const SubfolderListItem = ({ slug, folder }) => (
  <Stack
    as={Link}
    to={`/${slug}/folder/${folder.id}`}
    alignItems="flex-start"
    flexDirection="column"
    userSelect="none"
    cursor="pointer"
    minHeight="120px"
    rounded="lg"
    borderWidth={1}
    padding={6}
  >
    <Text fontSize="4xl" color="blue.100">
      <FontAwesomeIcon icon={faFolder} />
    </Text>
    <Text>{folder.name}</Text>
  </Stack>
);

const FolderPage = () => {
  const navigate = useNavigate();
  const { slug, folderId } = useParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();

  const {
    isOpen: showCreateCollectionModal,
    onOpen: openCreateCollectionModal,
    onClose: closeCreateCollectionModal
  } = useDisclosure();

  const {
    isOpen: showCreateFolderModal,
    onOpen: openCreateFolderModal,
    onClose: closeCreateFolderModal
  } = useDisclosure();

  const {
    isOpen: showDeleteFolderModal,
    onOpen: openDeleteFolderModal,
    onClose: closeDeleteFolderModal
  } = useDisclosure();

  const {
    isOpen: showUpdateFolderModal,
    onOpen: openUpdateFolderModal,
    onClose: closeUpdateFolderModal
  } = useDisclosure();

  const {
    isOpen: showCollectionManagersModal,
    onOpen: openCollectionManagersModal,
    onClose: closeCollectionManagersModal
  } = useDisclosure();

  const {
    data: folder,
    status,
    isSuccess
  } = useQuery(['folder', folderId], async () => {
    const { data } = await api.get(`/folders/${folderId}`);
    return data;
  });

  const { data: subfolders, isSuccess: subfoldersIsSuccess } = useQuery(
    ['subfolders', folderId],
    async () => {
      const { data } = await api.get(`/folders?parent=${folderId}`);
      return data;
    }
  );

  const createFolderMutation = useMutation(
    folder => api.post('/folders', folder),
    {
      onSuccess: ({ data: subfolder }) => {
        closeCreateFolderModal();
        toast({
          title: 'Folder was successfully created.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        queryClient.setQueryData(
          ['subfolders', folderId],
          [...subfolders, subfolder]
        );
        queryClient.setQueryData(['folder', folderId], {
          ...folder,
          metrics: {
            ...folder.metrics,
            subfolders: folder.metrics.subfolders + 1
          }
        });
      },
      onError: () => {
        toast({
          title: `Could not create folder. Please try again later.`,
          status: 'error',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const deleteFolderMutation = useMutation(
    () => api.delete(`/folders/${folderId}`),
    {
      onSuccess: () => {
        const folders = queryClient.getQueryData(['folders', 'parent']);
        if (folder.parent) {
          queryClient.setQueryData(
            ['folders', 'parent'],
            folders.filter(f => f.id !== folder.id)
          );
        }
        closeDeleteFolderModal();
        toast({
          title: 'Folder was successfully deleted.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        navigate(`/${slug}`);
      }
    }
  );

  const updateFolderMutation = useMutation(
    payload => api.patch(`/folders/${folderId}`, payload),
    {
      onSuccess: ({ data }) => {
        const folders = queryClient.getQueryData(['folders', 'parent']);
        queryClient.setQueryData(['folder', folderId], data);
        queryClient.setQueryData(
          ['folders', 'parent'],
          folders.map(folder => (folder.id === data.id ? data : folder))
        );
        closeUpdateFolderModal();
      }
    }
  );

  const handleOnCreateFolder = async data => {
    await createFolderMutation.mutateAsync({
      ...data,
      hive: folder.hive,
      parent: folder.id
    });
  };

  const handleOnDeleteFolder = async data => {
    await deleteFolderMutation.mutateAsync(data);
  };

  const handleOnUpdateFolder = async data => {
    await updateFolderMutation.mutateAsync(data);
  };

  const traverseFolders = folder => {
    let folders = [];
    if (folder.parent) {
      folders = [...traverseFolders(folder.parent), folder];
    } else {
      folders = [folder];
    }
    return folders;
  };

  const handleOnChangeManagers = async members => {
    const managers = members.map(member => member.id);
    await updateFolderMutation.mutateAsync({
      managers
    });
  };

  return (
    <Container maxW="container.lg" marginY={8}>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.folder').toLowerCase()
          })
        ]}
      >
        {isSuccess && (
          <>
            <Stack spacing={8}>
              <Stack direction={['column', null, 'row']}>
                <Heading fontSize="3xl">{folder.name}</Heading>
                <Spacer />
                <Flex justifyContent="flex-end">
                  <HStack spacing={4}>
                    <HStack>
                      <Text variant="muted">{t('common.access')}</Text>
                      <AvatarGroupButton
                        max={3}
                        members={traverseFolders(folder)[0].managers}
                        onClick={openCollectionManagersModal}
                      />
                    </HStack>
                    <ActionMenu>
                      <EditMenuItem onClick={openUpdateFolderModal} />
                      <DeleteMenuItem onClick={openDeleteFolderModal} />
                    </ActionMenu>
                  </HStack>
                </Flex>
              </Stack>
              <Flex alignItems="center" justifyContent="space-between">
                <Show below="lg">
                  <Menu>
                    <HStack>
                      <Button variant="link" as={Link} to={`/${slug}`}>
                        {t('common.hive')}
                      </Button>
                      <FontAwesomeIcon icon={faChevronRight} />
                      <MenuButton
                        as={Button}
                        rightIcon={<FontAwesomeIcon icon={faCaretDown} />}
                        variant="link"
                      >
                        {traverseFolders(folder).length === 0
                          ? t('common.hive')
                          : traverseFolders(folder)[
                              traverseFolders(folder).length - 1
                            ].name}
                      </MenuButton>
                    </HStack>
                    <MenuList>
                      {traverseFolders(folder).map(folder => (
                        <MenuItem
                          key={folder.id}
                          as={Link}
                          to={`/${slug}/folder/${folder.id}`}
                        >
                          {folder.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Show>
                <Show above="lg">
                  <Breadcrumb
                    separator={
                      <FontAwesomeIcon icon={faChevronRight} size="xs" />
                    }
                  >
                    <BreadcrumbItem>
                      <BreadcrumbLink as={Link} to={`/${slug}`}>
                        {t('common.hive')}
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                    {traverseFolders(folder).map((folder, i, arr) => (
                      <BreadcrumbItem
                        key={folder.id}
                        isCurrentPage={arr.length - 1 === i}
                      >
                        <BreadcrumbLink
                          as={Link}
                          to={`/${slug}/folder/${folder.id}`}
                        >
                          {folder.name}
                        </BreadcrumbLink>
                      </BreadcrumbItem>
                    ))}
                  </Breadcrumb>
                </Show>
                <FolderCreateMenu
                  folderId={folderId}
                  onAttachmentUploaded={_ => {
                    queryClient.setQueryData(['folder', folderId], {
                      ...folder,
                      metrics: {
                        ...folder.metrics,
                        attachments: folder.metrics.attachments + 1
                      }
                    });
                  }}
                  onClickCollection={openCreateCollectionModal}
                  onClickFolder={openCreateFolderModal}
                />
              </Flex>
              {folder.metrics.subfolders === 0 &&
                folder.metrics.collections === 0 &&
                folder.metrics.attachments === 0 && (
                  <Text variant="muted">{t('common.nothing_here_yet')}</Text>
                )}
              {folder.metrics.subfolders > 0 && (
                <Stack spacing={4}>
                  <Heading fontSize="lg">{t('common.folders')}</Heading>
                  <SimpleGrid
                    columns={[1, 2, 3, 4]}
                    spacing={4}
                    gridTemplateRows="masonry"
                  >
                    {subfoldersIsSuccess &&
                      subfolders.map(subfolder => (
                        <SubfolderListItem
                          key={subfolder.id}
                          slug={slug}
                          folder={subfolder}
                        />
                      ))}
                  </SimpleGrid>
                </Stack>
              )}
              {folder.metrics.collections > 0 && (
                <Stack spacing={4}>
                  <Heading fontSize="lg">{t('common.collections')}</Heading>
                  <FolderCollections folderId={folderId} />
                </Stack>
              )}
              {folder.metrics.attachments > 0 && (
                <Stack spacing={4}>
                  <Heading fontSize="lg">{t('common.attachments')}</Heading>
                  <SimpleGrid columns={[1, 2, 3, 4]} spacing={4}>
                    <AttachmentList
                      modelConfig={{
                        singular: 'folder',
                        plural: 'folders',
                        modelId: folderId
                      }}
                      canEdit
                      onAttachmentDeleted={_ => {
                        queryClient.setQueryData(['folder', folderId], {
                          ...folder,
                          metrics: {
                            ...folder.metrics,
                            attachments: folder.metrics.attachments - 1
                          }
                        });
                      }}
                    />
                  </SimpleGrid>
                </Stack>
              )}
            </Stack>
            <ModalWrapper
              title={t('common.folder')}
              isOpen={showUpdateFolderModal}
              onClose={closeUpdateFolderModal}
            >
              <FolderForm
                defaultValues={{ name: folder.name }}
                isOpen={showUpdateFolderModal}
                onClose={closeUpdateFolderModal}
                onSubmit={handleOnUpdateFolder}
              />
            </ModalWrapper>
            <ModalWrapper
              title={t('common.folder')}
              isOpen={showCreateFolderModal}
              onClose={closeCreateFolderModal}
              hasSteps
            >
              <FolderForm
                isOpen={showCreateFolderModal}
                onClose={closeCreateFolderModal}
                onSubmit={handleOnCreateFolder}
              />
            </ModalWrapper>
            <ModalWrapper
              size="full"
              isOpen={showCreateCollectionModal}
              onClose={closeCreateCollectionModal}
              hasSteps
            >
              <CollectionCreationForm
                isOpen={showCreateCollectionModal}
                onClose={closeCreateCollectionModal}
                folder={folder}
                onCollectionCreated={newCollection => {
                  const collections = queryClient.getQueryData([
                    'collections',
                    folderId,
                    'collections'
                  ]);
                  if (collections && collections.pages) {
                    const pages = collections.pages.map(page => {
                      return {
                        ...page,
                        count: page.count + 1
                      };
                    });
                    if (pages.length > 0) {
                      pages[0].results = [newCollection, ...pages[0].results];
                    }
                    queryClient.setQueryData(
                      ['folder', folderId, 'collections'],
                      {
                        ...collections,
                        pages
                      }
                    );
                  }
                  queryClient.setQueryData(['folder', folderId], {
                    ...folder,
                    metrics: {
                      ...folder.metrics,
                      collections: folder.metrics.collections + 1
                    }
                  });
                }}
                newCollection={null}
              />
            </ModalWrapper>
            <ConfirmationModal
              deleteText={t(
                'confirmation.this_information_will_be_permanently_removed_and_cannot_be_restored'
              )}
              isOpen={showDeleteFolderModal}
              onClose={closeDeleteFolderModal}
              onDelete={handleOnDeleteFolder}
            />
            <MembersModal
              members={traverseFolders(folder)[0].managers}
              title={t('common.access')}
              body={t('folder.access_explainer')}
              extra={
                folder.parent !== null ? (
                  <Flex alignSelf="flex-start">
                    <Button
                      as={Link}
                      to={`/${slug}/folder/${traverseFolders(folder)[0].id}`}
                      variant="link"
                      size="sm"
                      onClick={() => {
                        closeCollectionManagersModal();
                      }}
                    >
                      {t('folder.go_to_main_folder')}
                    </Button>
                  </Flex>
                ) : null
              }
              isOpen={showCollectionManagersModal}
              onClose={closeCollectionManagersModal}
              onChangeMembers={handleOnChangeManagers}
              canEdit={folder.parent === null}
            />
          </>
        )}
      </LoadingWrapper>
    </Container>
  );
};

export default FolderPage;
