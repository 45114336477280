import { useTranslation } from 'react-i18next';
import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react';
import {
  faFolder,
  faPlus,
  faRectangleHistory
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AttachmentUploadComponent from 'features/attachment/AttachmentUploadComponent';

const FolderCreateMenu = ({
  folderId,
  onAttachmentUploaded,
  onClickCollection,
  onClickFolder
}) => {
  const { t } = useTranslation();
  return (
    <Menu placement="bottom-end">
      <MenuButton as={Button} size="md" colorScheme="teal">
        <HStack>
          <FontAwesomeIcon icon={faPlus} />
          <Text>{t('common.add')}</Text>
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={onClickFolder}
          icon={<FontAwesomeIcon icon={faFolder} fixedWidth />}
        >
          {t('common.folder')}
        </MenuItem>
        <MenuItem
          onClick={onClickCollection}
          icon={<FontAwesomeIcon icon={faRectangleHistory} fixedWidth />}
        >
          {t('common.collection')}
        </MenuItem>
        <MenuDivider />
        <AttachmentUploadComponent
          modelConfig={{
            singular: 'folder',
            plural: 'folders',
            modelId: folderId
          }}
          onAttachmentUploaded={onAttachmentUploaded}
        />
      </MenuList>
    </Menu>
  );
};

export default FolderCreateMenu;
